<template>
  <div v-if="visible" class="image-viewer" @click="close">
    <div class="image-container" @click.stop>
      <!-- <img :src="imageUrl" alt="Enlarged Image" class="enlarged-image" /> -->
      <div class="zoomer">
        <img :src="imageUrl" alt="Enlarged Image" class="enlarged-image" />
      </div>
      <button class="click_generate_upload" @click="downloadImage(imageUrl)">download</button>
      <!-- <a :href="imageUrl" download="image.jpg" class="download-button">下载图片</a> -->
      <button class="close-button" @click="close">×</button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, watch } from 'vue';
import { downloadImage } from '@/imageViewerHandler';
// import VueImageZoomer from 'vue-image-zoomer'
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  imageUrl: {
    type: String,
    required: true,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const close = () => {
  console.log('Closing image viewer');
  emit('close');
};

watch(() => props.visible, (newVisible) => {
  console.log('Visible changed to:', newVisible);
});

// const downloadImage = async () => {
//   try {
//     console.log("props.imageUrl", props.imageUrl)
//     const response = await axios.get(props.imageUrl, {
//       responseType: 'blob', // 以二进制格式下载图片
//     });

//     // 创建一个链接元素
//     const url = window.URL.createObjectURL(new Blob([response.data]));
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', 'image.webp'); // 设置下载文件的名称
//     document.body.appendChild(link);
//     link.click();

//     // 移除链接元素
//     document.body.removeChild(link);
//     window.URL.revokeObjectURL(url);
//   } catch (error) {
//     console.error('下载图片失败:', error);
//   }
// };

</script>

<style scoped>
.image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.enlarged-image {
  max-width: 600px;
  max-height: 512px;
  object-fit: contain;
  overflow: scroll;
}

.click_generate_upload {

  /*路径*/

  /* left: 383px;
	top: 843px; */
  margin-top: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 4vw;
  font: 300 24px/1.5 PingFang SC, sans-serif;
  color: #f5f2f4;
  ;
  opacity: 1;
  background: rgb(16, 127, 238);
  border-radius: 20px;
  cursor: pointer;
  /* background-color: transparent; */
  border: none;
  cursor: pointer;

}

.download-button {
  margin-top: 20px;
  color: white;
  text-decoration: none;
  background: #333;
  padding: 10px 20px;
  border-radius: 5px;
}

.zoomer {
  max-width: 512px;
  max-height: 512px;
  object-fit: contain;
  overflow: scroll;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background: #ff5722;
  padding: 5px 10px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
}

@media screen and (max-width: 720px) {
  .enlarged-image {
    max-width: 60%;
    max-height: auto;
    object-fit: contain;
  }
  
  .click_generate_upload {
  
  
    margin-top: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 8vw;
    font: 300 24px/1.5 PingFang SC, sans-serif;
    color: #f5f2f4;
    ;
    opacity: 1;
    background: rgb(16, 127, 238);
    border-radius: 20px;
    cursor: pointer;
    /* background-color: transparent; */
    border: none;
    cursor: pointer;

    }
}
</style>