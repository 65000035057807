<template>
  <!-- <span "close" @click="change_show(false)">&times;</span> -->
  <div class="popup-overlay" @click.self="$emit('close')">
    <div class="popup-content">
      <span class="close" @click.self="$emit('close')">&times;</span>
      <!-- <div @click.self="$emit('close')"> -->
      <!-- </div> -->
      <div class="login_box_box">
        <div
          style="font-size: 24px; font-weight: 800; text-align: center;border-radius: 12px;background-color:aliceblue;margin-bottom: 30px;">
          {{ $t('Login_continue') }}
        </div>
        <button class="login_box" @click="login('google')">
          <div class="login_icon"
            style="background-image: url('https://img.js.design/assets/img/66b19e3699dc343afa9145d4.png#4e8f0cc76a43a58bf919d948e95b5247');">
          </div>
          <div class="google_text">
            {{ $t('LoginGoogle') }}
          </div>
        </button>
        <button class="login_box" @click="login('facebook')">
          <div class="login_icon"
            style="background-image: url('https://img.icons8.com/?size=100&id=uLWV5A9vXIPu&format=png&color=000000');">
          </div>
          <div class="google_text">
            {{ $t('LoginFacebook') }}
          </div>
        </button>
        <!-- <button class="login_box" @click="showLoginForm">
          <div class="login_icon"
            style="background-image: url('https://img.icons8.com/?size=100&id=X0mEIh0RyDdL&format=png&color=000000');">
          </div>
          <div class="google_text">
            Sign in with Email
           
          </div>
        </button> -->
        <!-- <div v-if="isLoginFormVisible">
          <input v-model="email" type="email" placeholder="Email" />
          <input v-model="password" type="password" placeholder="Password" />
          <button @click="login('email')">Sign in with Email</button>
        </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
// import { useStore } from 'vuex'
import { useUserStore } from '@/store/index';
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import app from '../firebaseConfig';
// import { getAuth, signInWithRedirect, GoogleAuthProvider } from 'firebase/auth';
import { auth, googleProvider, facebookProvider, signInWithEmailAndPassword } from '../firebaseConfig';
import { signInWithPopup } from 'firebase/auth';
import axiosInstance from '@/axios.config'; // 导入配置好的 Axios 实例


export default {
  name: 'LoginModal',
  // emits: ['close', 'login-success'],
  setup() {
    // const store = useStore()
    const userStore = useUserStore();
    // const username = ref('')
    // const password = ref('')
    const showLoginPopup = ref(true)
    // const auth = getAuth(app);
    // const provider = new GoogleAuthProvider();
    const email = ref('');
    const password = ref('');
    const isLoginFormVisible = ref(false)
    
    const showLoginForm = () => {
        isLoginFormVisible.value = true;
      }
    const login = async (type_name) => {
      try {
        let result;
        if (type_name === 'google') {
          result = await signInWithPopup(auth, googleProvider);
          // console.log(result);
          // console.log(typeof result);
          // console.log("result", result)
        } else if (type_name === 'facebook') {
          result = await signInWithPopup(auth, facebookProvider);
        } else if (type_name === 'email') {
          // console.log("email", type_name)
          result = await signInWithEmailAndPassword(auth, email.value, password.value);
        } else {
          throw new Error('Invalid login type');
        }
        const user = {
          name: type_name,
          uid: result.user.uid,
          udid:null,
          email: result.user.email,
          displayName: result.user.displayName,
          avatar: result.user.photoURL,
          userCredits:0
        };

        // 将用户信息存储在 localStorage 中
        // 更新 Vuex store
        // store.dispatch('login', user);


        // 发送用户信息到 Flask 后端
        const response = await axiosInstance.post('/api/login', user);
        console.log("777777",response)

        if (response.ok) {
          userStore.updateLoginStats(true)
        }

        const data = response.data;

        // 如果用户存在，获取用户积分数据
        user.udid = data.udid
        user.userCredits = data.userCredits;
        // console.log("userinfo", user)
        userStore.login(user, data.userCredits);
        // userStore.userCredit(data.userCreditser);

        // 更新 Vuex store 中的用户积分
        // store.dispatch('login', user);
        // store.dispatch('userCredit', user.userCredits);
        // store.dispatch("isLoggedIn",true)
        // localStorage.setItem('user', JSON.stringify(user));
        // localStorage.setItem('isLoggedIn', true);
        // localStorage.setItem('userCredit', user.userCredits);

        // console.log('User signed in:', user);
        // emit('login-success');

      } catch (error) {
        console.error('Google login error:', error, type_name);
      }
    };


    

    // const clientId = '496009136016-5vpucdspcp551nl65mkiml588e35sbcc.apps.googleusercontent.com';
    // const initGoogleSignIn = () => {
    //   window.gapi.load('auth2', () => {
    //     window.gapi.auth2.init({
    //       client_id: clientId
    //     });
    //   });
    // };

    // const login = async () => {
    //   const auth2 = window.gapi.auth2.getAuthInstance();
    //   try {
    //     const googleUser = await auth2.signIn();
    //     const profile = googleUser.getBasicProfile();
    //     console.log('User signed in:', profile.getName());
    //     emit('login-success')
    //     // 你可以在这里处理用户信息，例如发送到后端服务器进行验证
    //   } catch (error) {
    //     emit('login-success')
    //     console.error('Google Sign-In error:', error);
    //   }

    //   const user = {
    //     name: "name_type",
    //     avatar: 'https://img.icons8.com/?size=100&id=23240&format=png&color=000000'
    //   }
    //   // console.log("handleLogin", "name_type")
    //   store.dispatch('login', user)
    //   // emit('login-success')
    // };

    // onMounted(() => {
    //   initGoogleSignIn();
    // });

    // const login = (name_type) => {
    //   // Simulate login process
   
    //   const user = {
    //     name: name_type,
    //     avatar: 'https://img.icons8.com/?size=100&id=23240&format=png&color=000000'
    //   }
    //   console.log("handleLogin",  name_type)
    //   store.dispatch('login', user)
    //   emit('login-success')
    // }

    // const handleLogin = (name_type) => {
    //   username.value = name_type
    //   console.log("handleLogin", username.value, name_type)
    // }

    const change_show = (type) => {
      showLoginPopup.value = type
      // console.log("showLoginPopup", showLoginPopup.value)
    }


    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    // const firebaseConfig = {
    //   apiKey: "AIzaSyBQpoboennxLpw3K7y1MUGW0DkBT0p7yrc",
    //   authDomain: "new-first-a0c9f.firebaseapp.com",
    //   projectId: "new-first-a0c9f",
    //   storageBucket: "new-first-a0c9f.appspot.com",
    //   messagingSenderId: "997828231224",
    //   appId: "1:997828231224:web:c3dd855bf6e786ddbb66cd",
    //   measurementId: "G-4HD3PK5Z56"
    // };

    // Initialize Firebase
    // const app = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);



    return {
      // username,
      // password,
      login,
      // handleLogin,
      change_show,
      showLoginForm
    }


  }
}
</script>

<style scoped>

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.login_box_box {

  width: 400px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
}

.login_box {
  width: 80%;
  height: 30%;
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  /* margin-left: 15%; */
  background-color: #484848;
  align-items: center;
  border-radius: 12px;
  justify-content: center;
  cursor: pointer;


}

.google_text {
  /*gontinue with google*/

  /* left: 107px;
		top: 3px; */
  width: 90%;
  height: auto;
  opacity: 1;
  /** 文本1 */
  font-size: 20px;
  font-weight: 800;
  align-items: center;
  align-content: space-between;
  letter-spacing: 0px;
  line-height: 20px;
  color: #f5f2f4;
  vertical-align: top;
  /* border: #007bff solid 1px; */
}

.login_icon {
  width: 31px;
  height: 31px;
  /* margin-left: 15px; */
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* border:#f60e44 solid 1px; */
  /* margin-left: 10px; */
  margin-right: 10px;
}

</style>