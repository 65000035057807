<template>
  <div class="container">
    <!-- <LayerOut>
    </LayerOut> -->
    <div style="color: aliceblue;padding-left: 5vw;padding-right: 5vw;">
      <h3>
        Product Updates
      </h3>
      <p>
        Keep abreast of all the latest features, enhancements, and improvements we've introduced to elevate your Pook
        experience to new heights.
      </p>
    </div>
    <div class="grid">
      <a v-for="(item, index) in paginatedItems" :key="index" class="grid-item" :href="item.target_url" target="_blank"
        rel="noopener noreferrer">
        <div>
          <img :src="item.image" :alt="item.summary" class="grid-image" />
          <h5 class="grid-title">{{ item.title }}</h5>
          <!-- <p class="grid-summary">{{ item.summary }}</p> -->
        </div>
      </a>
    </div>
    <Pagination :totalItems="items.length" :itemsPerPage="itemsPerPage" @pageChanged="handlePageChange" />
    <!-- <FooterPage>
    </FooterPage> -->
  </div>

</template>

<script setup>
import { ref, computed } from 'vue';
// import LayerOut from '@/components/LayerOut.vue'
import Pagination from '@/components/Pagination.vue';
// import FooterPage from '@/components/footerPage.vue';

const itemsPerPage = ref(5);
const currentPage = ref(1);

const paginatedItems = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return items.value.slice(start, end);
});

const handlePageChange = (page) => {
  currentPage.value = page;
};

const items = ref([
  {
    image: new URL('../assets/images/news/best-ai-boyfriend-generators-dreambf.png', import.meta.url).href,
    title: '9 Best AI Boyfriend Apps & Sites in 2024 (Free & Paid): Your Guide to Virtual Romance',
    summary: 'Summary for item 1',
    target_url: `${window.location.origin}/best-ai-boyfriend-tools.html`
  },
  {
    image: new URL('../assets/images/news/face-swap-meme-with-donal-trump.jpg', import.meta.url).href,
    title: '6 Best AI Face Swap Meme Tools & Apps in 2024: Unleash Your Creativity!',
    summary: 'Summary for item 2',
    target_url: `${window.location.origin}/best-ai-face-swap-meme.html`
  },
  {
    image: new URL('../assets/images/news/faceswapper.ai-face-swap-testing.jpg', import.meta.url).href,
    title: '8 Best AI Face Swap Tools in 2024: Revolutionize Your Photo and Video Editing',
    summary: 'Summary for item 3',
    target_url: `${window.location.origin}/best-ai-face-swap-tools.html`
  },
  {
    image: new URL('../assets/images/news/best-ai-headshot-generator-vidnoz.png', import.meta.url).href,
    title: '11 Best AI Headshot Generators in 2024: Your Ultimate Guide',
    summary: 'Summary for item 4',
    target_url: `${window.location.origin}/best-ai-headshot-generators.html`
  },
  {
    image: new URL('../assets/images/news/artguru-ai-headshot-generator.png', import.meta.url).href,
    title: '9 Best AI Professional Headshot Generators in 2024: Elevate Your Professional Image',
    summary: 'Summary for item 5',
    target_url: `${window.location.origin}/best-ai-professional-headshot-generator.html`
  },
  {
    image: new URL('../assets/images/news/best-cartoonizer-picsart.jpg', import.meta.url).href,
    title: '5 Best Cartoonizer Tools in 2024',
    summary: 'Summary for item 6',
    target_url: `${window.location.origin}/best-cartoonizer-tools.html`
  },
  {
    image: new URL('../assets/images/news/pica-ai.png', import.meta.url).href,
    title: 'Top 5 Face Swap Apps (AI-Powered) for Photo and Video in 2024: Unleash Your Creative Potential',
    summary: 'Summary for item 7',
    target_url: `${window.location.origin}/best-face-swap-app.html`
  },
  {
    image: new URL('../assets/images/news/DeepSwap-Best-AI-Face-Swap-Porn-Overall.jpg', import.meta.url).href,
    title: "2024's Premier Face Swap Porn Tools: A Definitive Guide",
    summary: 'Summary for item 8',
    target_url: `${window.location.origin}/best-face-swap-porn-tools.html`
  },
  {
    image: new URL('../assets/images/news/ppnude-deepfake-porn-maker-online-free.png', import.meta.url).href,
    title: '11 Free Deepfake Porn Maker Online Tools in 2024 (TESTED)',
    summary: 'Summary for item 9',
    target_url: `${window.location.origin}/best-free-deepfake-porn-maker-online.html`
  },
  {
    image: new URL('../assets/images/news/dopamine-girl-ai-generator-neural-love.png', import.meta.url).href,
    title: '6 Best Dopamine Girl AI Generators and Face Swap Pron',
    summary: 'Summary for item 10',
    target_url: `${window.location.origin}/dopamine-girl-ai-generators.html`
  },
  {
    image: new URL('../assets/images/news/swapping-single-face-feature.png', import.meta.url).href,
    title: 'How to Add a Face to a Picture for Free with Face Swap Pron Insights',
    summary: 'Summary for item 11',
    target_url: `${window.location.origin}/how-to-add-a-face-to-a-picture.html`
  },
  {
    image: new URL('../assets/images/news/elsa-characters-face-swap-with-repunzel.png', import.meta.url).href,
    title: 'How to Create Disney Face Swaps with Face Swap Insights',
    summary: 'Summary for item 12',
    target_url: `${window.location.origin}/how-to-create-disney-face-swap.html`
  },
  {
    image: new URL('../assets/images/news/pet-switch-face-swap-pets.jpg', import.meta.url).href,
    title: 'How to Create Mike Wazowski Face Swap',
    summary: 'Summary for item 13',
    target_url: `${window.location.origin}/how-to-create-mike-wazowski-face-swap.html`
  },
  {
    image: new URL('../assets/images/news/Create-Infinite-Faces-with-AI-Face-Swap-Technology.webp', import.meta.url).href,
    title: 'What is Face-Swapping Technology?',
    summary: 'Summary for item 14',
    target_url: `${window.location.origin}/how-to-face-swap.html`
  }
]);
</script>

<style scoped>

.container {
  /*路径*/

  left: 0px;
  top: 0px;
  width: auto;
  height: auto;
  background: url("../assets/background.png");
  overflow: hidden;

}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3列 */
  gap: 5vw; /* 格子之间的间距 */
  padding: 2vw 5vw 5vw 5vw;
}

.grid-item {
  background-color: #f0f0f0;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  text-decoration: none;
}

.grid-image {
  width: 100%;
  height: 12rem;
  border-radius: 8px;
}

.grid-title {
  margin: 12px 0 8px;
  font-size: 18px;
}

.grid-summary {
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr; /* 1列 */
    gap: 2vw; /* 进一步缩小间距 */
    padding: 2vw 2vw 2vw 2vw; /* 进一步缩小内边距 */
  }

  .grid-image {
    height: 6rem; /* 进一步缩小图片高度 */
    width: 100%;
    object-fit:cover;
  }

  .grid-title {
    font-size: 14px; /* 进一步缩小标题字体大小 */
  }

  .grid-summary {
    font-size: 10px; /* 进一步缩小摘要字体大小 */
  }
}
</style>