import { createRouter, createWebHistory } from 'vue-router'
import SwapFace from '@/views/SwapFace.vue'
import NotFound from '@/views/NotFound.vue'
import ArticleList from '@/views/ArticleList.vue'
import ClothsPron from '@/views/ClothsPron.vue'
import i18n from '@/i18n'; // 引入 i18n 实例

const supportedLocales = ['en', 'ar', 'de', 'es', 'fr', 'hi', 'it', 'ja', 'ko', 'nl', 'pt', 'ru', 'tr', 'vi', 'zhCN', 'zhTW'];
// const defaultLanguage = 'en' // 默认语言

const routes = [
  {
    path: '/:locale',
    component: {
      template: '<router-view />',
    },
    children: [
      {
        path: '',
        name: 'face_swap',
        component: SwapFace,
      },
      {
        path: 'cloth_swap',
        name: 'undress_cloths',
        component: ClothsPron,
      },
      {
        path: 'blogs',
        name: 'BlogList',
        component: ArticleList,
      }
    ]
  },
  // 默认语言为英文
  {
    path: '/',
    component: SwapFace,
  },
  {
        path: '/cloth_swap',
        name: 'undress_cloths',
        component: ClothsPron,
      },
  {
        path: '/blogs',
        name: 'BlogList',
        component: ArticleList,
      },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundRoot',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const newLocale = to.params.locale; // 获取当前路由的语言参数
  console.log("router.beforeEach",newLocale)
  // 检查语言是否存在
  if (newLocale && !supportedLocales.includes(newLocale)) {
    // 如果语言不存在，重定向到 404 页面
    next({ name: 'NotFoundRoot' });
    return;
  }
   // 更新 i18n 的 locale
  if (newLocale && i18n.global.locale !== newLocale) {
    i18n.global.locale = newLocale;
    console.log("i18n.global.locale",i18n.global.locale )
  }

  // 继续导航
  next();
});

export default router