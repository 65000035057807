<template>
  <!-- <div class="container"> -->
  <div class="topcontainer">
    <div v-if="isSmallScreen" style="padding: 10px;margin-right: 1.2rem;" @click="toggleSidebar">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#0A0A0A" d="M3 4h18v2H3zm0 7h12v2H3zm0 7h18v2H3z"></path></svg>
    </div>
    <div v-if="showSidebar" class="sidebar" @click="hidenSidebar">
    <ul>
      <li @click="navigateToFaceSwap">{{ $t('face_swap') }} <span class="arrow">&gt;</span></li>
      <li @click="navigateToClothSwap">{{ $t("swap_cloth") }}  <span class="arrow">&gt;</span></li>
      <li @click="navigateToBlogs">{{ $t('Blog') }}  <span class="arrow">&gt;</span></li>
    </ul>
  </div>
  <div v-if="showSidebar" class="blur-background">

  </div>
    <div class="logotype" @click="navigateToHome">
      <img src="../assets/face_swapping_logo.png" alt="face swapping">
    </div>
    <div class="linktype">
      <div class="gongneng" @click="navigateToFaceSwap">
        <div>
          <span>{{ $t('face_swap') }}</span>
          <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M36 19L24 31L12 19H36Z" fill="#ffffff" stroke="#ffffff" stroke-width="2" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
      <div class="gongneng" @click="navigateToClothSwap">
        <div>
          <span>{{ $t("swap_cloth") }} </span>
          <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M36 19L24 31L12 19H36Z" fill="#ffffff" stroke="#ffffff" stroke-width="2" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
    </div>
    <div v-if="isLoggedIn" class="logintype">
      <div>
        <button class="gongneng" style="font-weight: 100; font-size: 15px;flex-direction: row;margin: 5px;" @click="showCredists">
          <img src="../assets/icons8.png" alt="" class="icons">
          {{ userCredits }}
        </button>
      </div>
      <!-- <div>
					<img src="https://img.icons8.com/?size=100&id=23240&format=png&color=000000" alt="" class="img">
				</div> -->
      <div>
        <img :src="user_avater" alt="" class="avater" 
          @click="toggleMenu" @mouseenter="showMenu = true" @mouseleave="showMenu = false">
      </div>
      <div class="dropdown" v-if="showMenu" @mouseenter="showMenu = true" @mouseleave="showMenu = false">
        <div
          style="display: flex; flex-direction: row;align-items: center;justify-content: center;background-color: #6e7072;border-radius: 5px;">
          <img src="../assets/icons-out.png" alt="" style="width: 15%; height: auto;">
          <button @click="logout">{{ $t("Logout") }}</button>
        </div>
        <div
          style="display: flex; flex-direction: row;align-items: center;justify-content: center;background-color: #6e7072;border-radius: 5px;">
          <img src="../assets/icons-image-result.png" alt="" style="width: 15%; height: auto;">
          <button @click="toggleDisplay('my_result')">{{ $t("my_result") }}</button>
        </div>
      </div>
      <!-- <div class="myresult-list" v-show="showMyResult">
					<img v-for="(result, index) in myResults" :key="index" :src="result.src" alt="" class="myresult-img">
				</div> -->
    </div>
    <div v-else class="logintype">
      <!-- <div>
					<button @click="showLoginPopup = true" class="gongneng">Login</button>
				</div>
				<div>
					<button @click="showLoginPopup = true" class="gongneng">Sigin</button>
				</div> -->
      <div>
        <button class="gongneng" style="font-weight: 100; font-size: 15px;flex-direction: row;margin: 5px;" @click="showCredists">
          <img src="../assets/icons8.png" alt=""  class="icons">
          {{ userCredits }}
        </button>
      </div>
      <div style="margin-left: 1rem;background: linear-gradient(83deg, #d6fa30 -1%, #5ce8fa 103%);border-radius: 8px;">
        <button v-if="!isLoggedIn" @click="showLoginModal = true" class="gongneng" style="align-items: center;justify-content: center;padding: 10px;color: rgb(159, 154, 243);margin-right: 0px;">
            {{ $t("Login") }}
        </button>
      </div>
      <!-- <div>
        <button v-if="!isLoggedIn" @click="showLoginModal = true" class="gongneng">
          <span>
            {{ $t("Sigin") }}
          </span>
        </button>
      </div> -->
      <LoginModal v-if="showLoginModal || showlogin" @close="showLoginModal = false, showlogin = false" />
    </div>
    <div v-if="showCreditsModal || showSub">
      <User_Credits @close="showCreditsModal = false, showSub = false" />
      <div v-if="showCreditsModal || showSub" class="blur-background"></div>
    </div>
    <!-- <div>
				<h1>Home</h1>
				<button v-if="!isLoggedIn" @click="showLoginModal = true">Login</button>
				<div v-else>
					<img :src="user.avatar" alt="Avatar" />
					<button @click="logout">Logout</button>
				</div>
				<LoginModal v-if="showLoginModal" @close="showLoginModal = false" @login-success="handleLoginSuccess" />
			</div> -->
  </div>
  <!-- </div> -->
</template>

<script setup lang='js'>
import { ref, computed, onMounted, watchEffect,onBeforeUnmount } from 'vue';
import { auth} from '../firebaseConfig';
import { signOut } from 'firebase/auth';
// import { useStore } from 'vuex'
import LoginModal from '@/views/LoginModal.vue';
import User_Credits from '@/views/Credit.vue';
// import axios from 'axios';
import { useUserStore, activeDivStore  } from '@/store/index';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n'
// import { useHead } from '@vueuse/head'

// const globalMessage = ref(false);
const router = useRouter();
const userStore = useUserStore();
const useActiveDivStore = activeDivStore()

const { locale } = useI18n()
watchEffect(() => {
  // console.log(userStore.lang)
  locale.value = userStore.lang
})

// function changeLanguage(lang) {
//   locale.value = lang
//   // 更新元数据
//   useHead({
//     title: t('title'),
//     meta: [
//       {
//         name: 'description',
//         content: t('description')
//       }
//     ]
//   })
// }

const isLoggedIn = computed({
  get() {
    return userStore.isLoggedIn;
  },
  set(value) {
    userStore.isLoggedIn = value;
  }
});
onMounted(() => {
  if (!isLoggedIn.value) {
    // console.log("====-----==",isLoggedIn.value)
    userStore.updateNoneLoginUserInfo()
  } else {
    // console.log("====",isLoggedIn.value)
    userStore.fetchCredits()
  }
});
const showSub = ref(false)
const showlogin = ref(false);

function navigateToHome() {
  router.push("/")
}

function navigateToFaceSwap() {
  router.push("/")
}

function navigateToClothSwap() {
  router.push("/cloth_swap")
}

function navigateToBlogs() {
  router.push("/blogs")
}



// const user = computed(() => userStore.isLoggedIn ? userStore.user : userStore.guestUser);
const user_avater = computed(() => userStore.avater);
// const guestUser = computed(() => userStore.guestUser);
const userCredits = computed(() => userStore.isLoggedIn ? userStore.userCredit : userStore.guestCredit);
// console.log("userCredits**layer**", userCredits.value, showSub, showlogin)

watchEffect(() => {
  if (userStore.showLogin == true) {
    showlogin.value = true
    showCreditsModal.value = false
  }
  if (userStore.showSubcrib == true) {
    showSub.value = true
    showLoginModal.value = false
    showlogin.value = false
  }

  if (userStore.showSubcrib == false) {
    showSub.value = false
  }
});



/*

登录登出逻辑

*/
const logout = () => {
  userStore.updateLoginStats(false);
  isLoggedIn.value = false
  // console.log("signOut")
  // window.location.reload();
  navigateToHome()
  updateLogOutInfo()
}

// 异步执行
const updateLogOutInfo = async () => {
  await signOut(auth);
  // localStorage.setItem('isLoggedIn', false);
  // localStorage.removeItem('user');
  // localStorage.removeItem('userCredit');
  userStore.logout()
  userStore.updateNoneLoginUserInfo()
}
// const store = useStore()
const showLoginModal = ref(false)
const showCreditsModal = ref(false)

const showCredists = () => {
  showCreditsModal.value = true
}



const showMenu = ref(false);
// const showMyResult = ref(false);
const toggleMenu = () => {
  showMenu.value = !showMenu.value;
  // if (!showMenu.value) {
  //   // showMyResult.value = false; // 隐藏图片列表
  // }
};

const toggleDisplay = (div) => {
  useActiveDivStore.updateactiveDiv(div);
  // // 如果切换到 'my_result'，则请求后端获取结果
  if (div === 'my_result') {
    // console.log("==========+++++")
    userStore.fetchResultUrls();
    navigateToFaceSwap()
  }
};

const isSmallScreen = ref(false);
const showSidebar = ref(false);
const toggleSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const hidenSidebar = () => {
  showSidebar.value = false;
};

const handleResize = () => {
  isSmallScreen.value = window.innerWidth < 768;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
  handleResize(); // 初始化时调用一次
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

// const fetchResultUrls = async () => {
//   try {

//     const response = await axiosInstance.post('/api/swap_face/myresult', { "isLogin": isLoggedIn.value, "udid": user.value.udid });
//     const data = await response.data;
//     // console.log("====", data.result_urls)
//     userStore.updateMyresult(data.result_urls);
//     console.log("====", userStore.mylib_result)
//   } catch (error) {
//     console.error('Error fetching results:', error);
//   }
// };

</script>

<style scoped lang="css">

.container {
  /*路径*/

  left: 0px;
  top: 0px;
  width: auto;
  height: auto;
  background: url("../assets/background.png");
  overflow: hidden;

}

.content_box_height {
  height: 650px;
  /* border: #de11e5 solid 1px; */
}

.topcontainer {

  /* border: #007bff solid 1px; */
  overflow: hidden;
  width: auto;
  height: 60px;
  background: url("../assets/daohanglan.png");
  /* overflow: hidden; */
  /* padding: auto; */
  display: flex;
  /* 如果需要水平居中也可以添加： */
  align-items: center;
  /* 设置高度，例如： */
  /* height: 100vh; */
}

.logotype {
  /* border: #343a40 solid 1px; */
  /* float: left; */
  margin-left: -1.8vw;
  margin-right: 20px;
  margin-top: 20px;
  /* background-image: url("../assets/face_swapping_logo.png"); */
  background-size: contain;
  width: auto;
  /* 父容器宽度 */
  height: auto;
  /* 父容器高度，可以根据需要调整 */
  background-position: center;
  /* 图片居中 */
  background-repeat: no-repeat;
  /* 防止图片重复 */
  overflow: hidden;
  /* border-radius: 50%; */
  /* border:#007bff solid 1px; */
  cursor: pointer;

}

.logotype img {
  width: 15vw;
  height: auto;
}

.linktype {
  /* border: #f462c8 solid 1px; */
  display: flex;
  /* justify-content:center; */
  /* 如果需要水平居中也可以添加： */
  align-items: center;
  width: 70%;
  margin-left: 0px;
  /* 设置高度，例如： */
  /* height: 100vh; */
}


.gongneng {
  /* border: #343a40 solid 1px; */
  display: flex;
  float: left;
  margin-left: 0px;
  margin-right: 30px;
  padding: 20px 0;
  /*sign up*/

  /* font-size: 24px; */
  font-weight: 200;
  letter-spacing: 0px;
  line-height: 26px;
  color: rgb(244, 243, 250);
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
  text-align: center;
  vertical-align: top;
  background-color: transparent;
  border: none;
  cursor: pointer;
  /* outline: none; */
}

.gongneng span {
  white-space: nowrap;
  /* 防止文字换行 */
  font-size: 2rem;
  /* 使用相对单位 */
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #f8f8f8;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  /* padding: 20px; */
  z-index: 999999;
}



.sidebar ul {
  width: 100%;
  height: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}
.sidebar.show {
  transform: translateX(0);
}
.sidebar ul li {
  
  /* background: #d5e6f8; */
  width: 92%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 20px 0; */
  font-size: 18px;
  line-height: 4rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  padding-left: 15px;
  /* padding-top: 15px; */
  font-weight: 500;
  font-family: math;
  border-bottom: 1px solid #c595f4;

}

.arrow {
  margin-left: auto;
}

.blur-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(5px);
	z-index: 998;
}

.dropdown {

  display: flex;
  position: absolute;
  top: 30px;
  right: 0;
  width: auto;
  height: 200px;
  background-color: rgb(63, 62, 63);
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  color: #f5f2f4;
  padding: 10px;
  z-index: 1000;
  flex-direction: column;
  margin: 20px auto;
  /* border: none; */
  cursor: pointer;
  /* border: #007bff solid 1px; */
  gap: 10px;
  overflow: hidden;
}

.dropdown button {
  width: 120px;
  /* 控制按钮宽度 */
  height: 40px;
  /* 控制按钮高度 */
  padding: 0;
  /* 去除默认 padding */
  font-size: 16px;
  /* 控制字体大小 */
  border: none;
  /* 去除默认边框 */
  background-color: #6e7072;
  /* 按钮背景颜色 */
  color: white;
  /* 按钮文字颜色 */
  cursor: pointer;
  /* 鼠标悬停时显示为指针 */
  border-radius: 5px;
  /* 按钮圆角 */
}

.gongneng:hover {
  color: #976af2;
  /* 悬浮时背景颜色变化 */
  transition: color 0.3s ease, transform 0.3s ease;
  /* transform: translateY(-5px); */
  /* 悬浮时向上移动 */
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
  /* 悬浮时添加阴影 */
}
.avater {
    width: 3.5rem;
    border-radius: 50%;
    margin-left: 1rem;
  }
.icons { 
  width: 2.5vw; 
  height: auto;
  margin: 0.5vw;
  }
.gongneng:active {
  background-color: transparent;
}

.logintype {
  /* border: #00ff40; */
  float: right;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 30px;
  font-size: 24px;
  font-weight: 400;
  padding: 20px 0;
}
@media screen and (max-width: 720px) {

  .icons { 
    width: 5vw; 
    height: auto;
    margin: 4vw;
  }

  .avater {
    width: 3rem;
    border-radius: 50%;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .content_box_height {
    height: 400px;
    /* border: #de11e5 solid 1px; */
  }

  .topcontainer {
    height: 15vw;
    /* border: #f5f2f4 solid 2px; */
  }

  .logotype {
    /* border: #343a40 solid 1px; */
    /* float: left; */
    margin-left: -5vw;
    margin-top: 10px;
    width: auto;
    /* 父容器宽度 */
    height: auto;
    /* 父容器高度，可以根据需要调整 */
    overflow: hidden;
  }

  .logotype img {
    width: 30vw;
    height: auto;
  }

  .gongneng {
    /* border: #343a40 solid 1px; */
    float: left;
    margin-left: 0px;
    margin-right: 10px;
    padding: 6px 0;
    /*sign up*/

    font-size: 12px;
    /* font: inherit; */
    font-weight: 200;
    letter-spacing: 0px;
    line-height: 10px;
    color: rgb(59, 57, 57);
    text-align: center;
    vertical-align: top;
    background-color: transparent;
    border: none;
    cursor: pointer;
    /* border:#ee0505 solid 1px; */
    /* outline: none; */
  }

  .gongneng:hover {
    color: #976af2;
    /* 悬浮时背景颜色变化 */
    transition: color 0.3s ease, transform 0.3s ease;
    transform: translateY(0px);
    /* 悬浮时向上移动 */
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
    /* 悬浮时添加阴影 */
  }

  .gongneng:active {
    background-color: transparent;
  }

  .linktype {
    display: none;
  }

  .logintype {
    /* border: #00ff40; */
    float: right;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 15px;
    font-size: 12px;
    font-weight: 200;
    padding: 8px 0;
  }
}
</style>