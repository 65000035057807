<template>
  <div class="pagination">
    <button @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">Previous</button>
    <button v-for="page in totalPages" :key="page" @click="goToPage(page)" :class="{ active: currentPage === page }">
      {{ page }}
    </button>
    <button @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
  totalItems: {
    type: Number,
    required: true,
  },
  itemsPerPage: {
    type: Number,
    default: 10,
  },
});

const emit = defineEmits(['pageChanged']);

const currentPage = ref(1);

const totalPages = computed(() => Math.ceil(props.totalItems / props.itemsPerPage));

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
    emit('pageChanged', currentPage.value);
  }
};
</script>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'pagination_page'
});
</script>


<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  background-color: #fff;
  cursor: pointer;
}

button.active {
  background-color: #a6aaa8;
  color: #fff;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>