
<!-- // eslint-disable-next-line vue/multi-word-component-names -->
<script setup>
import { ref} from 'vue';
// import { useI18n } from 'vue-i18n'
// import { useUserStore } from '@/store/index';
// const userStore = useUserStore();

// // 语言支持

// const {locale } = useI18n()

// watchEffect(() => {
// 	// console.log(userStore.lang)
// 	locale.value = userStore.lang
// })

// //语言支持


const contentBox = ref(null)

const scrollToTop = () => {
	if (contentBox.value) {
		contentBox.value.scrollIntoView({ behavior: 'auto' });
	}
};


const personSwapItems = ref([
	{
		imageUrl: new URL('../assets/images/swap-face/Celebrity-Face-Swap.webp', import.meta.url).href,
		title: 'Celebrity Face Swap',
		description: 'Experience the excitement of swap your face with character, embrace the joy of becoming your favorite singer, actor, Marvel or DC movies star.',
		alt: "Swap faces with celebrity Johnson"
	},
	{
		imageUrl: new URL('../assets/images/swap-face/The-girl-swapped-faces-in-her-selfie-picture.webp', import.meta.url).href,
		title: 'Selfie Face Swap',
		alt: 'The girl swapped faces in her selfie picture',
		description: 'Discover a new you with virtual try-on.Explore different facial features, hairstyles, makeups, and genders for a fun and creative twist.'
	},
	{
		imageUrl: new URL('../assets/images/swap-face/The-male-friend-swapped-faces-in-the-picture.webp', import.meta.url).href,
		title: 'Friends and Family Face Swap',
		alt: 'The male friend swapped faces in the picture',
		description: 'Revamp your photos with a touch of creativity by changing the faces of your friends and family.'
	},
	{
		imageUrl: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-funny-meme.webp', import.meta.url).href,
		title: 'Funny Memes Face Swap',
		alt: 'The face swapped picture generated a funny meme',
		description: 'Get playful with well - aimed memes, such as transforming your boss into the superhero or adding your face to iconic memes.'
	},
	{
		imageUrl: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-good-looking-avatar.webp', import.meta.url).href,
		title: 'Face Swap Avatars',
		alt: 'The face swapped picture generated a good looking avatar',
		description: 'Unlock endless possibilities with customizable avatars and face swap features. Create personalized avatars that truly represent you.'
	},
	{
		imageUrl: new URL('../assets/images/swap-face/The-face-swapped-picture-generated-a-professional-career-headshot.webp', import.meta.url).href,
		title: 'Face Swap Likeness for Business',
		alt: 'The face swapped picture generated a professional career headshot',
		description: 'Elevate your business with our face swap solution.Attract leads with captivating swaps and create professional AI headshots.'
	}
])




const questions = ref([
	{
		question: "What is AI face swap?",
		answer: "AI face swap technology replaces faces in photos or videos using artificial intelligence.",
		showAnswer: false
	},
	{
		question: "Do I need technical skills to use a face swapper?",
		answer: "No, you don’t. Pook’s AI face swapping tool is easy to use and requires no other complicated manual operations. You just need to follow our guides to upload two photos. With one simple click, the face swap process will be done within seconds.",
		showAnswer: false
	},
	{
		question: "How does AI face swap work?",
		answer: "It uses AI to detect faces, map their features, and seamlessly blend a new face into the original image or video.",
		showAnswer: false
	},
	{
		question: "Is pook's face swap tool safe?",
		answer: "Yes, it's safe. We only use your photos for face swapping and delete them within 24 hours of upload.",
		showAnswer: false
	},
]);

const toggleAnswer = (index) => {
	questions.value[index].showAnswer = !questions.value[index].showAnswer;
};

const reviews = ref([
	{
		image: 'https://d2uvllf6n93h2s.cloudfront.net/76c89227-f7ad-4bfa-820d-e9e07c265c4d.webp',
		name: 'jack - College Student',
		stars: 5,
		comment: "This face swap tool is awesome! I created a hilarious 90s yearbook photo of myself and shared it with friends, who all had a good laugh. It's super easy to use and incredibly fast, making it a fun and quick way to transform your photos.",
	},
	{
		image: 'https://d2uvllf6n93h2s.cloudfront.net/a06dc153-c0f7-4054-a935-f6f58905dc0e.webp',
		name: 'Jamie - Software',
		stars: 5,
		comment: "This tool was a lifesaver for our friend group holiday photo! We usually take a group pic, but couldn't get together this year. So I used the face swap feature to put us all in Christmas sweaters. It's not perfect, but it's fun and kept our tradition alive. The tool is easy to use and made the process quick and enjoyable."
	},
	{
		image: 'https://d2uvllf6n93h2s.cloudfront.net/136410f1-f473-4f15-be36-dcebe215be62.webp',
		name: 'Alex - Social Media Influencer',
		stars: 5,
		comment: "I've been using this face swap tool for my social media posts. I put myself in some movie scenes, and people really liked the Godfather one. It's quick to use, and the results are pretty good. This tool has given me ideas for content I wouldn't have thought of before, making it a valuable addition to my social media strategy."
	}
]);

</script>




<template>
	<!-- <div>
		<h1>{{ $t('title') }}</h1>
		<p>{{ $t('description') }}</p>
		<button @click="changeLanguage('en')">changeLanguage</button>
	</div> -->
	<div class="container">
		<div class="row_doc_box_base">
			<div class="fdvfd">
				<img v-lazy="require('../assets/images/swap-face/Create-Infinite-Faces-with-AI-Face-Swap-Technology.webp')"
					alt="Create-Infinite-Faces-with-AI-Face-Swap-Technology" class="box_img_base">
			</div>
			<div class="img_doc1_box_box">
				<div class="box_text_base">
					<h3>
						{{ $t('img_doc1') }}
					</h3>
				</div>
				<div class="box_text_base2">
					<p>
						{{ $t('img_doc1-1') }}
					</p>
				</div>
				<div>
					<button class="swap_button_style box_center" style="margin-top: 5vw;" @click="scrollToTop">
						{{ $t('face_swap') }}
					</button>
				</div>
			</div>
		</div>
		<div class="doc2_box text">
			{{ $t('img_doc1-mid') }}
		</div>
		<div class="row_doc_box_base" style="height: auto;">
			<div v-for="(item, index) in personSwapItems" :key="index" class="doc3_box_1"
				style="display: flex;flex-direction: column;">
				<div style="width: auto;height: auto;">
					<img v-lazy="item.imageUrl" alt="" class="doc3_box_1_image">
				</div>
				<div class="doc3_box_1_text_box">
					<div class="doc3_box_1_text">

						<h5>
							{{ $t(`title${index + 1}`) }}
						</h5>
						<!-- {{ item.title }} -->
					</div>
					<div class="doc3_box_1_text2">
						<p>
							{{ $t(`description${index + 1}`) }}
						</p>
						<!-- {{ item.description }} -->
					</div>
				</div>
			</div>
		</div>
		<div>
			<button class="swap_button_style box_center" @click="scrollToTop">
				{{ $t('face_swap') }}
				<!-- swap face -->
			</button>
		</div>
		<div class="row_doc_box_base">
			<div class="img_doc1_box_box">
				<div class="box_text_base">
					<h3>
						{{$t('img_doc2')}}
					</h3>

					<!-- Swap Face Online Swiftly and Flawlessly -->
				</div>
				<div class="box_text_base2">
					<p>
						{{ $t('img_doc2-1') }}
					</p>
				</div>
				<div>
					<button class="swap_button_style box_center" style="margin-top: 5vw;" @click="scrollToTop">
						{{ $t('face_swap') }}
					</button>
				</div>
			</div>
			<div class="fdvfd">
				<img v-lazy="require('../assets/images/swap-face/Swap-Face-Online-Swiftly-and-Flawlessly.webp')"
					alt="Swap Face Online Swiftly and Flawlessly" class="box_img_base">
			</div>
		</div>
		<div class="row_doc_box_base">
			<div class="fdvfd">
				<img v-lazy="require('../assets/images/swap-face/Instant-Face-Morphing-with-Our-Trending-Preset-Photos.webp')"
					alt="Instant Face Morphing with Our Trending Preset Photos" class="box_img_base">
			</div>
			<div class="img_doc1_box_box">
				<div class="box_text_base">
					<h3>
						{{ $t('img_doc3') }}
					</h3>
				</div>
				<div class="box_text_base2">
					<p>
						{{ $t('img_doc3-1') }}
					</p>
				</div>
				<div>
					<button class="swap_button_style box_center" style="margin-top: 5vw;" @click="scrollToTop">
						{{ $t('face_swap') }}
					</button>
				</div>
			</div>
		</div>
		<div class="row_doc_box_base">
			<div class="img_doc1_box_box">
				<div class="box_text_base">
					<h3>
						{{ $t('img_doc4') }}
					</h3>
				</div>
				<div class="box_text_base2">
					<p>
						{{ $t('img_doc4-1') }}
					</p>
				</div>
				<div>
					<button class="swap_button_style box_center" style="margin-top: 5vw;" @click="scrollToTop">
						{{ $t('face_swap') }}
					</button>
				</div>
			</div>
			<div class="fdvfd">
				<img v-lazy="require('../assets/images/swap-face/Easy-and-Safe-AI-Face-Replacement.webp')"
					alt="Easy-and-Safe-AI-Face-Replacement" class="box_img_base">
			</div>
		</div>
		<div class="row_doc_box_base">
			<div class="fdvfd">
				<img v-lazy="require('../assets/images/swap-face/Face-Swap-on-the-Go-for-Android-and-iPhone.webp')"
					alt="Face-Swap-on-the-Go-for-Android-and-iPhone" class="box_img_base">
			</div>
			<div class="img_doc1_box_box">
				<div class="box_text_base">
					<h3>
						{{ $t('img_doc5') }}
					</h3>
				</div>
				<div class="box_text_base2">
					<p>
						{{ $t('img_doc5-1') }}
					</p>
				</div>
				<div>
					<button class="swap_button_style box_center" style="margin-top: 5vw;" @click="scrollToTop">
						{{ $t('face_swap') }}
					</button>
				</div>
			</div>
		</div>
		<div class="doc2_box text">
			<h3>
				{{ $t('swap_method_title') }}
			</h3>
		</div>
		<div style="display: flex;flex-direction: column;width: 100%; height: auto;">
		<div class="row_doc_box_base" style="
    margin-left: 0px;
    margin-right: 0px;
">
			<div class="doc3_box_1">
				<div>
					<img v-lazy="require('../assets/images/swap-face/upload-your-face-to-face-swap.jpg')"
						alt="How to Swap Face Online" class="doc3_box_1_image">
				</div>
				<div class="doc3_box_1_text_box">
					<div class="doc3_box_1_text">
						<h5>
							{{ $t('swap_method_one_step') }}
						</h5>
					</div>
					<div class="doc3_box_1_text2">
						<p>
							{{ $t('swap_method_one_step_detail') }}
						</p>
					</div>
				</div>
			</div>
			<div class="doc3_box_1">
				<div>
					<img v-lazy="require('../assets/images/swap-face/upload-target-face-or-template-to-face-swap.jpg')"
						alt="How to Swap Face Online" class="doc3_box_1_image">
				</div>
				<div class="doc3_box_1_text_box">
					<div class="doc3_box_1_text">
						<h5>
							{{ $t('swap_method_two_step') }}
						</h5>
					</div>
					<div class="doc3_box_1_text2">
						<p>
							{{ $t('swap_method_two_step_detail') }}
						</p>
					</div>
				</div>
			</div>
			<div class="doc3_box_1">
				<div>
					<img v-lazy="require('../assets/images/swap-face/clik-face-swap-to-generate.jpg')"
						alt="How to Swap Face Online" class="doc3_box_1_image">
				</div>
				<div class="doc3_box_1_text_box">
					<div class="doc3_box_1_text">
						<h5>
							{{ $t('swap_method_three_step') }}
						</h5>
					</div>
					<div class="doc3_box_1_text2">
						<p>
							{{ $t('swap_method_three_step_detail') }}
						</p>
					</div>
				</div>
			</div>
		</div>
		<div>
			<button class="swap_button_style box_center" style="margin-top: 5vw;" @click="scrollToTop" >
				{{ $t('face_swap') }}
			</button>
		</div>
		</div>
		<div class="doc2_box text">
			{{ $t('user_say_title') }}
		</div>
		<div class="row_doc_box_base">
			<div v-for="(review, index) in reviews" :key="index" class="buy_back_box">
				<div class="buy_back_box_text">
					<div>
						<img :src="review.image" alt="">
					</div>
					<div>
						<h5>
							{{ $t(`name${index + 1}`) }}
						</h5>
					</div>
				</div>
				<div class="stars">
					<img v-for="star in review.stars" :key="star" src="../assets/Star.png" alt="">
				</div>
				<div class="buy_back_box_text2">
					<p>
						{{ $t(`comment${index + 1}`) }}
					</p>
					<!-- {{ review.comment }} -->
				</div>
			</div>
		</div>
		<div
			style="color: #f5f2f4;display: flex;font-size:2rem;font-weight: 400;width: 100%;height: auto;align-items: center;justify-content: center;flex-direction: column;margin-bottom: 2rem;padding-top: 20px;">
			<div>
				{{$t('FAQS')}}
			</div>
			<div style="font-size: 1rem;">
				{{ $t('concat_us' ,{email: 'support@imagefaceswap.com' }) }}
			</div>
		</div>
		<div
			style="display: flex;flex-direction: column; width: 90%;height: auto; padding: 10px;text-align: left;align-items: center;justify-content: center;">
			<div v-for="(item, index) in questions" :key="index" class="question-item">
				<div class="question-title" @click="toggleAnswer(index)">
					<h3>
						{{ $t(`question${index + 1}`) }}
					</h3>
				</div>
				<div v-if="item.showAnswer" class="question-answer">
					<p>
						{{ $t(`answer${index + 1}`) }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="css">

.question-item {
	width: 85%;
	/* text-align: left; */
	margin-left: 0px auto;
	margin-bottom: 10px;
	color: #1f1f1f;
	/* border: #a7b0ba; */
	padding: 10px;
	border-radius: 5px;

}

.question-title {
	display: flex;
	align-items: center;
	background: #f5f2f4;;
	width: 90%;
	padding: 20px;
	height: 1.3rem;
	cursor: pointer;
	font-weight: bold;
	border-radius: 10px;
}

.question-answer {
	color: #b8b3b3;
	margin-top: 10px;
	padding-left: 20px;
}

.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
}

.popup-content {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	position: relative;
}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 20px;
	cursor: pointer;
}

form {
	display: flex;
	flex-direction: column;
}

label {
	margin-top: 10px;
}

input {
	padding: 10px;
	margin-top: 5px;
	/* border: 1px solid #ccc; */
	border-radius: 3px;
}

button[type="submit"] {
	margin-top: 20px;
	padding: 10px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 3px;
	cursor: pointer;
}

button[type="submit"]:hover {
	background-color: #0056b3;
}

.blur-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(5px);
	z-index: 999;
}

.login_box_box {

	width: 400px; 
	height: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.3);
}

.login_box {
	width: 80%;
	height: 20%;
	display: flex; 
	flex-direction: row;
	margin: 10px auto ;
	/* margin-left: 15%; */
	background-color: #484848;
	align-items: center;
	border-radius: 12px;
	justify-content: center;
	cursor: pointer;
	
	
}

.google_text {
	/*gontinue with google*/
	
		/* left: 107px;
		top: 3px; */
		width: 90%;
		height: auto;
		opacity: 1;
		/** 文本1 */
		font-size: 20px;
		font-weight: 800;
		align-items: center;
		align-content: space-between;
		letter-spacing: 0px;
		line-height: 20px;
		color: #f5f2f4;
		vertical-align: top;
		/* border: #007bff solid 1px; */
}

.login_icon {
	width: 31px;
	height: 31px;
	/* margin-left: 15px; */
	background-size: 90%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	/* border:#f60e44 solid 1px; */
	/* margin-left: 10px; */
	margin-right: 10px;
}

.content {
	padding: 20px;
}

/* .container {
	display: flex;
	align-items: center;
	padding: 20px;
} */

.container {
	/*路径*/
	
		left: 0px;
		top: 0px;
		width:auto;
		height: auto;
		background: url("../assets/background.png");
		overflow: hidden;
		
}

.content_box_height {
	height: 650px;
	/* border: #de11e5 solid 1px; */
}

.topcontainer {

	/* border: #007bff solid 1px; */
	overflow: hidden;
	width: auto;
	height: 60px;
	background: url("../assets/daohanglan.png");
	/* overflow: hidden; */
	/* padding: auto; */
	display: flex;
	/* 如果需要水平居中也可以添加： */
	align-items: center;
	/* 设置高度，例如： */
	/* height: 100vh; */
}

.logotype{
	/* border: #343a40 solid 1px; */
	/* float: left; */
	margin-left:-1.8vw ;
	margin-right: 20px;
	margin-top: 20px;
	/* background-image: url("../assets/face_swapping_logo.png"); */
	background-size: contain;
	width: auto; /* 父容器宽度 */
  height: auto; /* 父容器高度，可以根据需要调整 */
  background-position: center; /* 图片居中 */
  background-repeat: no-repeat; /* 防止图片重复 */
	overflow: hidden;
	/* border-radius: 50%; */
	/* border:#007bff solid 1px; */
	
}

.logotype img {
	width: 15vw;
	height: auto;
}

.linktype {
	/* border: #f462c8 solid 1px; */
	display: flex;
	/* justify-content:center; */
	/* 如果需要水平居中也可以添加： */
	align-items: center;
	width: 70%;
	margin-left: 0px;
	/* 设置高度，例如： */
	/* height: 100vh; */
}

.gongneng {
	/* border: #343a40 solid 1px; */
	display: flex;
	float: left;
	margin-left: 0px;
	margin-right: 30px;
	padding: 20px 0;
	/*sign up*/

	/* font-size: 24px; */
	font-weight: 200;
	letter-spacing: 0px;
	line-height: 26px;
	color: rgb(248, 238, 238);
	align-items: center; /* 垂直居中对齐 */
  justify-content: center; /* 水平居中对齐 */
	text-align: center;
	vertical-align: top;
	background-color: transparent;
	border: none;
	cursor: pointer;
	/* outline: none; */
}

.gongneng span {
	white-space: nowrap;
	/* 防止文字换行 */
	font-size: 1.5em;
	/* 使用相对单位 */
}

.dropdown {
	
	display: flex;
	position: absolute;
	top: 30px;
	right: 0;
	width: auto;
	height: 200px;
	background-color: rgb(63, 62, 63);
	/* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
	color: #f5f2f4;
	padding: 10px;
	z-index: 1000;
	flex-direction: column;
	margin: 20px auto;
	/* border: none; */
	cursor: pointer;
	/* border: #007bff solid 1px; */
	gap: 10px;
	overflow: hidden;
}

 .dropdown button {
		width: 120px;
		/* 控制按钮宽度 */
		height: 40px;
		/* 控制按钮高度 */
		padding: 0;
		/* 去除默认 padding */
		font-size: 16px;
		/* 控制字体大小 */
		border: none;
		/* 去除默认边框 */
		background-color: #6e7072;
		/* 按钮背景颜色 */
		color: white;
		/* 按钮文字颜色 */
		cursor: pointer;
		/* 鼠标悬停时显示为指针 */
		border-radius: 5px;
		/* 按钮圆角 */
 }

.gongneng:hover {
	color: #976af2;
	/* 悬浮时背景颜色变化 */
	transition: color 0.3s ease, transform 0.3s ease;
	/* transform: translateY(-5px); */
	/* 悬浮时向上移动 */
	/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
	/* 悬浮时添加阴影 */
	}

 .gongneng:active {
	background-color: transparent;
 }



.logintype {
	/* border: #00ff40; */
	float: right;
	display: flex;
	align-items: center;
	margin-left: auto;
	margin-right: 30px;
	font-size: 24px;
	font-weight: 400;
	padding: 20px 0;
}

.content_box {
	/* float: left; */
	/* border: #f5f2f4 solid 1px; */
	display: flex;
	/* align-items: center; */
	overflow: hidden;
	/* position: relative; */
	
}

.content_weight {
	margin-left: 70px;
}

.swap_box {
	/*路径*/
		/* left: 183px;
		top: 220px; */
		position:relative;
		display:flex;
		margin-top: 40px;
		margin-right: 10px;
		height: auto;
		align-items: center;
		flex-direction: column;
		/* justify-content:center; */
		/* text-align: center; */
		/* justify-content:flex-end; */
		width: 65%;
		/* opacity: 1; */
		/* border: #ef24c0 solid 1px; */
		background: rgb(249, 250, 250);
}

.col_content {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	/* border: #ef24c0 solid 1px; */
}

.col_content2 {
	position: relative;
	display: flex;
	/* align-items:center; */

	flex-direction: column;
	/* border: #ef24c0 solid 1px; */
}

.row_content_back {
	background: rgb(247, 242, 242);
	/*路径*/
}

.row_content {
		position: relative;
		display: flex;
		/* align-items: center;
		justify-content:center; */
		flex-direction:row;
		/* border: hsl(167, 67%, 67%) solid 1px; */
}

.row_content_margin {

	margin-left: 10px;
	margin-top: 10px;
	width: 100%;
	height: 50%;
}

.row_content_margin_child {

	margin-left: 10px;
	margin-top: 10px;
	margin-right: 20px;
	position: relative;
	width: 55%;
	height: 90%;
	object-fit: cover;
	border-radius: 12px;
	/* border: #b07ee2 solid 1px; */
	/* overflow: hidden; */
	/* background-size: 100% 100%; */
}

.row_content_margin_child.hover {
	background-color: #838385; /* 更深的灰色背景 */
  border-color: #888;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 更深的阴影效果 */
}

.click_generate {

	/*路径*/
	
	/* left: 383px;
	top: 843px; */
	margin-top: 2vw;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70%;
	height: 4vw;
	font: 100 24px/1.5 PingFang SC, sans-serif;
	color: #f5f2f4;
	opacity: 1;
	background: rgb(158, 156, 156);
	border-radius: 20px;
	cursor: pointer;
	/* background-color: transparent; */
	border: none;
	cursor: pointer;

}

.click_generate_upload {

	/*路径*/

	/* left: 383px;
	top: 843px; */
	margin-top: 2vw;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70%;
	height: 4vw;
	font: 300 24px/1.5 PingFang SC, sans-serif;
	color: #f5f2f4;;
	opacity: 1;
	background: rgb(16, 127, 238);
	border-radius: 20px;
	cursor: pointer;
	/* background-color: transparent; */
	border: none;
	cursor: pointer;

}

/* .loading-spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 36px;
	height: 36px;
	border-radius: 50%;
	border-top-color: #3498db;
	animation: spin 1s linear infinite;
} */
 .loading-spinner {
  width: 36px;
  height: 36px;
  background-image: url('../assets/loading2.png'); /* 替换为你的图片路径 */
  background-size: cover;
  animation: spin 1s linear infinite;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.img {

	/* max-width:  90%;
	height: 90%; */ 
	/* 可以根据需要调整高度 */
	/* background-image: url("https://img.mobiuspace.com/image/aigc/95698695c820af829243c7d0eeb4579d.webp"); */
	/* background-size: 80%; */
	background-position:center;
	background-repeat: no-repeat;
	background-size: contain;/* 保持图片原始宽高比，覆盖整个容器 */
	/* border: #007bff solid 1px; */
	
	

	/* overflow: hidden; */

}

.upload_img {
	/* width: 60%;
	/* height: 90%; */

	background-size: 60% auto;
	background-position: center;
	background-repeat: no-repeat;
	object-fit: cover; 
	background-image: url("../assets/upload-img.png");
	background-color: transparent;
	border: none;
	cursor: pointer;

}

.upload_img:hover {
	color: #976af2;
	/* 悬浮时背景颜色变化 */
	transition: color 0.3s ease, transform 0.3s ease;
	transform: translateY(-5px);
	/* 悬浮时向上移动 */
	/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
	/* 悬浮时添加阴影 */
}



.tempalte_box {
	/*路径*/
	
		/* left: 1273px;
		top: 97px; */
		position: relative;
		display: flex;
		margin-left: auto;
		margin-top: 40px;
		margin-right: 30px;
		/* margin-bottom: 900; */
		width: 30%;
		height: auto;
		opacity: 1;
		/* border: #7610f2 solid 1px; */
		background: rgba(21, 14, 38, 1);
}
.swap_word1 {
	/*free face swap online*/
	
		/* left: 435px;
		top: 241px; */
		/* margin-top: -4vw; */
		/* margin-bottom: 0.5vw; */
		width: 100%;
		height: 7rem;
		text-align: center;
		/* opacity: 1; */
		/* display: flex; */
		/* position: absolute; */
		/** 文本1 */
		font-size: calc(50px + 0.5vw);
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 7px;
		color: rgba(0, 0, 0, 1);
		vertical-align: top;
		/* border:#007bff solid 1px */
}

.swap_word2 {
	/*experience fun or real swaps: select sin*/
	
		/* left: 290px;
		top: 329px;
		width: 810px;
		height: 29px; */
		/* position: absolute; */
		display: flex;
		justify-content: center;
		opacity: 1;
		/** 文本1 */
		margin-top: 1px;
		display: flex;
		font-size: calc(20px + 0.5vw);
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 8px;
		color: rgba(0, 0, 0, 1);
		vertical-align: top;
		padding: 5px;
		/* border: #ef24c0 solid 1px; */
}

.font_name3 {
	/*face swap*/
	
		/* left: 462px;
		top: 373px; */
		/* width: 118px;
		height: 35px; */
		opacity: 1;
		position: relative;
		/** 文本1 */
		margin-top: 3px;
		margin-left: 30px;
		margin-right: 30px;
		display: flex;
		font-size: 24px;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 35px;
		color: rgba(112, 112, 112, 1);
		text-align: right;
		vertical-align: top;
		/* border: #00ff40 solid 1px; */
		background-color: transparent;
		border: none;
		cursor: pointer;
}

.font_name3.isActive::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 3px;
	background:linear-gradient(to right, #1f1f1f, #007bff);
	border-radius: 8px;
	bottom:-10px
}

.font3_box {
	/* border: #00ff40 solid 1px; */
	margin-top: 10px;
	margin-left: 30px;
	display: flex;
	flex-direction:row;
	justify-content: space-between;
	align-items: center;
	/* margin-top: 20px;
	margin-left: 30px; */
	/* justify-content: space-between; */
	/* float:inline-start; */
	width: 90%;
	height: auto;
}

.template_img {
	position: relative;
	display: flex;
	gap: 15px;
	flex-wrap: wrap;
	/* border: #00ff40 solid 1px; */
	/* margin-bottom: 10px; */
}

.template_img img {
	position: relative;
	width: calc(50% - 20px);
	height: auto;
	justify-content: space-between;
	margin: 5px;
	/* margin-top: 50px; */
	/* margin-bottom: 10px; */
	/* width: 20%; */
	object-fit:cover;
	border-radius: 10px;
	/* 50%宽度减去间距 */
	box-sizing:border-box;
	align-items: center;
	
}

.selected-image {
	border-color: #b07ee2 solid 2px;
}

.template_type {

	display: flex;
	width: 100%;
	height: auto;
	position: relative;
	margin-top: 80px;
	display: flex;
	background: rgba(44, 39, 56, 1);
	/* align-items:center; */
	flex-direction: column;
	/* border: #ef24c0 solid 1px; */
}

.template_type_select {

	/*sporty*/
	
	/* left: 26px;
	top: 5.5px;
	width: 50px;
	height: 23px;
	opacity: 1; */
	/** 文本1 */
	display: flex;
	position:relative;
	margin: 5px;
	/* padding: auto; */
	text-align: center;
	align-items: center;
	justify-content:center;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 23px;
	color: #f5f2f4;
	/* border: none; */
	/* background: seashell; */
	vertical-align: top;
	background-color: #01080f;
	cursor: pointer;

	/*路径*/
	
	/* left: 0px;
	top: 0px; */
	width: 102px;
	height: 35px;
	/* opacity: 1; */
	/* border: 1px solid rgba(72, 52, 212, 1); */
	background-color: transparent;
	border: none;
	cursor: pointer;

}

/* .template_type_select:hover { */
	/* background-color: rgb(246, 246, 248); */
	/* 悬停时背景颜色变为深蓝色 */
/* } */


.template_type_select.isActive {
	/*路径*/
	
		/* left: 0px;
		top: 0px; */
		/* content: ''; */
		/* position: absolute; */
		/* left: 0; */
		/* width: 102px;
		height: 35px; */
		/* opacity: 1; */
		background: rgba(104, 109, 224, 1);
		box-shadow: 0px 1px 2px rgba(20, 14, 62, 0.25);
		z-index: 2;
}

.upload_template {
	position:absolute;
	display: flex;
	margin-top: 20px;
	margin-bottom: 50px;
	margin-left: 20px;
	flex-direction: row;
	justify-content:center;
	align-items: center;
	width: 90%;
	height: 30px;

	/*路径*/
	/* width: 506px; */
	/* height: 40px; */
	/* opacity: 1; */
	background: rgba(204, 204, 204, 0.3);

	/** 文本1 */
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 38px;
	color: #f5f2f4;;
	vertical-align: top;
	/* border: #007bff solid 1px; */
	cursor: pointer;
	/* background-color: transparent; */
	border: none;
	/* cursor: pointer; */
}

.img_doc {
	width: auto;
	height: 40vw;
	display: flex;
	/* position: relative; */
	/* border: #00ff40 solid 1px; */
	/* align-items: center;
	justify-content: center; */
	flex-direction: row;

}

.img_doc1_box {
	/*face-swap-and-demonstrate-the-ability-to-generate-various-different-faces-of-a-man 1*/
		display: flex;
		margin-top: 5vw;
		overflow: hidden;
		width: 50%;
		height: 419px;
	}


.img_doc1_box_box {
	/*create infinite faces with al face swap technology*/

	/* left: 915px; 
		top: 1168px; */
	/* margin-top: 50%;
	margin-left: 50%; */
	/* margin: 10px; */
	/* float: left; */
	position: relative;
	display: flex;
	width: 50%;
	margin-top: 5vw;
	height: auto;
	opacity: 1;
	/* border: #ff0055 solid 1px; */
	flex-direction: column;
	align-items: center;
	justify-content: center;

}
.img_doc1_box_doc1 {
	/*create infinite faces with al face swap technology*/
	
		/* left: 915px; 
		top: 1168px; */
		margin-top: 0vw;
		margin-left: 1vw;
		position: absolute;
		display: flex;
		width: 90%;
		height: auto;
		opacity: 1;
		/** 文本1 */
		font-size: 20px;
		font-weight: 400;
		letter-spacing: 0px;
		color: #f5f2f4;;
		vertical-align: top;
		/* border: #007bff solid 1px; */
}

.img_doc1_box_doc2 {

/*ai face swap has surged in popularity as a dynamic photo trend. its advanced algorithms accurately detect and track facial features, allowing users to effortlessly create realistic face swaps. Pook ai face swapper is one of the best free online tools for face swapping, you can use it to seamlessly swap face in any photo and enjoy endless fun while engaging with realistic face swap photos.*/

margin-top: 8vw;
margin-left: 1vw;
width: 90%;
height: auto;
opacity: 1;
position: absolute;
display: flex;
/** 文本1 */
font-size: 26px;
font-weight: 400;
letter-spacing: 0px;
/* line-height: 12px; */
color: #f5f2f4;;
vertical-align: top;
/* border: #f462c8 solid 1px; */
}

.swap_boten {
	display: flex;
	position: absolute;
	margin-top: 20vw;
	margin-left: 10vw;
	width: 358px;
	height: 92px;
	opacity: 1;
	background: rgba(72, 52, 212, 1);
	/* border: #00ff40 solid 1px; */
	cursor: pointer;
	border: none;
}

.swap_boten.text {
	
	width: 178px;
	height: 38px;
	opacity: 1;
	/** 文本1 */
	font-size: 26px;
	font-weight: 400;
	align-items: center;
	justify-content: center;
	letter-spacing: 0px;
	line-height: 38px;
	color: #f5f2f4;;
	text-align: center;
	vertical-align: top;
}

.doc2_box {
		/* left: 500px;
		top: 1886px; */
		/* margin-left: 5vw; */
		
		display: flex;
		align-items: center;
		justify-content: center;
		/* margin-top: 2vw; */
		margin: 2vw auto;
		width: 90%;
		height: 160px;
		opacity: 1;
		font-size: 40px;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 60px;
		color: #f5f2f4;;
		/* background: #d7e3f0; */
		vertical-align: top;
		text-align: center;
		/* 文字向左对齐 */
		word-wrap: break-word;
		/* 允许长单词或 URL 地址换行到下一行 */
		/* border: #7610f2 solid 1px;; */
}

.row_doc_box_base {

	display:flex;
	position: relative;
	float: left;
	margin-top: 10px;
	margin: 10px auto;
	width: auto;
	height: auto;
	/* opacity: 1; */
	flex-direction: row;
	/* overflow: hidden; */
	flex-wrap: wrap;
	/* 允许换行 */
	justify-content: space-between;
	/* 项目之间的间距均匀分布 */
	/* border: #00ff40 solid 1px; */
	overflow: hidden;
}

.col_doc_box_base {

	display: flex;
	position: relative;
	float: left;
	margin-top: 10px;
	width: auto;
	height: 40vw;
	opacity: 1;
	flex-direction: column;
	/* overflow: hidden; */
	/* flex-wrap: wrap; */
	/* 允许换行 */
	/* justify-content: space-between; */
	/* 项目之间的间距均匀分布 */
	/* border: #00ff40 solid 1px; */
	overflow: hidden;
}

/* 第一块图文 */
.doc3_box_1 {

	/*rectangle 13*/
	
	
	/* position: relative; */
	/* margin-top: 10px; */
	/* margin-left: 5vw; */
	margin: 10px auto;
	/* left: 0px;
	top: 100px; */
	width: 31%;
	height: 40%;
	/* opacity: 1; */
	/* background: rgba(40, 25, 65, 1); */
	/* border: #ef24c0 solid 1px; */
}

.doc3_box_1_image {
	max-width: 65%;
	/* 图片最大宽度为容器的100% */
	max-height: 65%;
	/* 图片最大高度为容器的100% */
	display: block;
	/* 将图片转换为块级元素 */
	margin: 0 auto;
	/* 水平居中 */
	object-fit: cover;
	overflow: hidden;
}

.doc3_box_1_text_box {

	/* opacity: 1; */
	display: flex;
	/* line-height: 1vw; */
	flex-direction: column;
	padding-left: 1vw;
	padding-right: 1vw;
	/* padding-bottom: 1vw; */
	padding-top: 0.15vw;
	border-radius: 10px;
	background: rgba(255, 255, 255, 1);
	height: 11vw;
}




.doc3_box_1_text {


/*ai face swap has surged in popularity*/

	/* left: 83px;
	top: 2399px;
	width: 474px;
	height: 40px;
	opacity: 1; */
	/** 文本1 */

	font-size: 1.4em;
	font-weight: 350;
	letter-spacing: 0px;
	line-height: auto;
	color: rgba(0, 0, 0, 1);
	vertical-align: top;
	/* width: 90%; */
	/* 文本最大宽度为容器的100% */
	/* display: block; */
	/* 将文本转换为块级元素 */
	/* margin: 0 auto; */
	/* 水平居中 */
	/* text-align: left; */
	/* 文字向左对齐 */
	text-wrap:wrap ;
	/* 允许长单词或 URL 地址换行到下一行 */
	/* font-size: 24px; */
	/* color: #f5f2f4; */
	/* border: #ff0055 solid 1px;; */
}


.doc3_box_1_text2 {

	/** 文本1 */
	font-size: 1em;
	font-weight: 00;
	letter-spacing: 0px;
	line-height: auto;
	color: rgba(0, 0, 0, 1);
	vertical-align: top;
	text-wrap:wrap ;

}

.swap_button_style {
	width: 8em;
	height: 2em;
	opacity: 1;
	font-size: 1.3em;
	font-weight: 100;
	color: #f5f2f4;
	background: rgba(72, 52, 212, 1);
	/* border: #00ff40 solid 1px; */
	cursor: pointer;
	border-radius: 15px;
	border: none;
}

.box_center {
	display: flex;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
}

/* 第3块图文 */
.box_text_base {

	margin-left: 5vw;
	/* padding: 2vw; */
	font-size: 1.4em;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: auto;
	color: rgb(250, 247, 247);
	vertical-align: top;
	text-align: center;
	/* width: 90%; */
	/* 文本最大宽度为容器的100% */
	/* display: block; */
	/* 将文本转换为块级元素 */
	/* margin: 0 auto; */
	/* 水平居中 */
	/* text-align: left; */
	/* 文字向左对齐 */
	word-wrap: break-word;

}


.box_text_base2 {

	/** 文本1 */
	margin-top: 2vw;
	margin-left: 5vw;
	font-size: 1em;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: auto;
	color: rgb(248, 245, 245);
	vertical-align: top;
	word-wrap: break-word;

}

.box_img_base {

	/* max-width: 50%; */
	/* 图片最大宽度为容器的100% */
	float: left;
	max-width: 90%;
	max-height: auto;
	/* 图片最大高度为容器的100% */
	display: block;
	/* 将图片转换为块级元素 */
	margin: 0 auto;
	/* 水平居中 */
	object-fit: cover;
}

.fdvfd {
	/*rectangle 14*/
	
		/* left: 1104px;
		top: 3571px; */
		/* margin-top: 7vw; */
		margin: 6vw auto;
		width: 616px;
		height: 453px;
		opacity: 1;
		background: rgba(217, 217, 217, 0);
		border: 2px solid rgba(79, 142, 217, 1);
		/* overflow: hidden; */
		/* object-fit:auto; */
}


.buy_back_box {
	/*rectangle 23*/
	
		/* left: 109px;
		top: 8298px; */
		display: flex;
		/* position: absolute; */
		width: 30%;
		height: auto;
		opacity: 1;
		border-radius: 8px;
		background: #c2cad2;
		flex-direction: column;
		border-radius: 12px;
		align-items: center;
		/* justify-content: center; */
		/* background: rgba(255, 255, 255, 1); */
		/* border: #007bff solid 1px; */
}
	
.buy_back_box_text {
		/*free and quaility face swaps*/
	
		/* left: 119px;
		top: 8322px; */
		/* width: 470px;
		height: 46px; */
		display: flex;
		/* justify-items: center; */
		align-items: center;
		/* justify-content: space-between; */
		/* margin: 2px auto; */
		width: 100%;
		height: 60px;
		border-radius: 12px;
		/** 文本1 */
		font-size: 1.5rem;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: auto;
		color: rgb(11, 11, 11);
		vertical-align: top;
		background-color: #f5f2f4;
	
}

.buy_back_box_text2 {
	/*free and quaility face swaps*/
	/* margin: 2px auto; */
	/** 文本1 */
	display: flex;
	/* margin-top: 50px; */
	width: 80%;
	height: auto;
	padding: 1em;
	font-size: 1rem;
	font-weight: 300;
	letter-spacing: 0px;
	line-height: auto;
	color: rgb(25, 24, 24);
	vertical-align: top;
	flex-wrap: wrap;
	text-align: left;
	align-items: center;
	justify-content: center;
	border-radius: 12px;

	/* overflow: scroll; */

}

.buy_back_box_text img {

	padding: 10px;

	width: 50px;
	/* 设置图片的宽度 */
	height: 50px;
	/* 设置图片的高度 */
	border-radius: 50%;
	/* 将图片裁剪成圆形 */
	object-fit: cover;
	/* 确保图片内容被裁剪成圆形 */
	margin-left: 10px;
}

.stars {
	/*star 1*/
	/* position: absolute; */
	
	margin: 1vw auto;
	/* border: #00ff40 solid 1px; */
	/* width: auto; */
	/* height: 30px; */
	/* display: flex; */
	margin-top: 10px;
	width: 90%;
	height: auto;
	text-align: center;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	/* object-fit: cover; */
}

.faq {
	
		/*1.can i face swap pnline for free*/
		display: flex;
		align-items: center;
		justify-content: left;
		margin: 5vw auto;
		width: 80%;
		height: 46px;
		opacity: 1;
		/** 文本1 */
		font-size: 20px;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 46px;
		color: rgba(255, 255, 255, 1);
		vertical-align: top;
		/* border: #a694a0 solid 1px; */
}

.conect_us {

	display: flex;
	align-items: center;
	justify-content: left;
	/* margin: 30px auto; */
	flex-direction: row;
	color: #f5f2f4;
	letter-spacing: 2px;
	word-spacing: 2px;
	border-top: #3e3f3e solid 2px;

}

.connect_us_box {
	display: flex;
	margin: 10px;
}

/* 小屏幕设备 */
@media screen and (max-width: 720px) {

	.container {
		display: flex;
		flex-direction: column;
		}
	
		.content_box_height {
			height: 400px;
			/* border: #de11e5 solid 1px; */
		}
	
		.topcontainer {
			height: 10vw;
			/* border: #f5f2f4 solid 2px; */
		}
	
		.logotype {
			/* border: #343a40 solid 1px; */
			/* float: left; */
			margin-left: -5vw;
			margin-top: 10px;
			width: auto; /* 父容器宽度 */
			height: auto; /* 父容器高度，可以根据需要调整 */
			overflow: hidden;
		}

		.logotype img {
			width: 30vw;
			height: auto;
		}

		.gongneng {
			/* border: #343a40 solid 1px; */
			float: left;
			margin-left: 0px;
			margin-right: 10px;
			padding: 6px 0;
			/*sign up*/
	
			font-size: 12px;
			font-weight: 200;
			letter-spacing: 0px;
			line-height: 10px;
			color: rgb(59, 57, 57);
			text-align: center;
			vertical-align: top;
			background-color: transparent;
			border: none;
			cursor: pointer;
			/* border:#ee0505 solid 1px; */
			/* outline: none; */
		}
	
		.gongneng:hover {
			color: #976af2;
			/* 悬浮时背景颜色变化 */
			transition: color 0.3s ease, transform 0.3s ease;
			transform: translateY(0px);
			/* 悬浮时向上移动 */
			/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
			/* 悬浮时添加阴影 */
		}
	
		.gongneng:active {
			background-color: transparent;
		}
	
		.linktype {
			display: none;
		}

		.logintype {
			/* border: #00ff40; */
			float: right;
			display: flex;
			align-items: center;
			margin-left: auto;
			margin-right: 15px;
			font-size: 12px;
			font-weight: 200;
			padding: 8px 0;
		}
	
		.content_box {
			/* float: left; */
			/* border: #f5f2f4 solid 1px; */
			display: flex;
			/* align-items: center; */
			overflow: hidden;
			/* position: relative; */
			flex-direction: column;
	
		}
	
		.content_weight {
			margin-left: 30px;
		}
	
		.swap_box {
			margin-top: auto;
			margin-right: 0px;
			margin-left: 0%;
			width: auto;
			overflow: hidden;
			/* border: #de11e5 solid 1px; */
		}
	
		.col_content {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			/* border: #ef24c0 solid 1px; */
		}
	
		.col_content2 {
			position: relative;
			display: flex;
			/* align-items:center; */
	
			flex-direction: column;
			/* border: #ef24c0 solid 1px; */
		}
	
		.row_content_back {
			background: rgb(247, 242, 242);
			/*路径*/
		}
	
		.row_content {
			position: relative;
			display: flex;
			/* align-items: center;
			justify-content:center; */
			flex-direction: row;
			/* border: hsl(167, 67%, 67%) solid 1px; */
		}
	
		.row_content_margin {
	
			margin-left: 10px;
			margin-top: 10px;
			width: 100%;
			height: 50%;
		}
	
		.row_content_margin_child {
	
			margin-left: 10px;
			margin-top: 10px;
			margin-right: 20px;
			position: relative;
			width: 55%;
			height: 90%;
			object-fit: cover;
			border-radius: 12px;
			/* overflow: hidden; */
			/* background-size: 100% 100%; */
		}
	
		.click_generate {
	
			/*路径*/
	
			/* left: 383px;
		top: 843px; */
			margin-top: 5vw;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 70%;
			height: 10vw;
			font: 100 12px/1.5 PingFang SC, sans-serif;
			color: rgba(255, 255, 255, 1);
			opacity: 1;
			background: rgb(158, 156, 156);
			border-radius: 20px;
			cursor: pointer;
			/* background-color: transparent; */
			border: none;
			cursor: pointer;
			/* border: orchid solid 1px; */
	
		}
	
		.click_generate_upload {
	
			/*路径*/
	
			/* left: 383px;
		top: 843px; */
			margin-top: 5vw;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 70%;
			height: 10vw;
			font: 300 18px/1.5 PingFang SC, sans-serif;
			color: rgba(255, 255, 255, 1);
			opacity: 1;
			background: rgb(16, 127, 238);
			border-radius: 20px;
			cursor: pointer;
			/* background-color: transparent; */
			border: none;
			cursor: pointer;
	
		}
	
		.img {
	
			/* width: 90%;
		height: 90%; */
			/* 可以根据需要调整高度 */
			/* background-image: url("https://img.mobiuspace.com/image/aigc/95698695c820af829243c7d0eeb4579d.webp"); */
			background-size: 80%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			max-width: 100px;
			/* 保持图片原始宽高比，覆盖整个容器 */
			/* border: #007bff solid 1px; */
	
	
	
			/* overflow: hidden; */
	
		}
	
		.upload_img {
			/* width: 60%;
		/* height: 90%; */
	
			background-size: 50%;
			background-position: center;
			background-repeat: no-repeat;
			object-fit: cover;
			background-image: url("../assets/icon-upload.368442cb.png");
			background-color: transparent;
			border: none;
			cursor: pointer;
			/* border:#976af2 solid 1px; */
	
		}
	
		.upload_img:hover {
			color: #976af2;
			/* 悬浮时背景颜色变化 */
			transition: color 0.3s ease, transform 0.3s ease;
			transform: translateY(-5px);
			/* 悬浮时向上移动 */
			/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
			/* 悬浮时添加阴影 */
		}
	
	
	
		.tempalte_box {
			/*路径*/
	
			/* left: 1273px;
			top: 97px; */
			position: relative;
			display: flex;
			margin-left: 10px;
			margin-top: 10px;
			margin-right: 10px;
			/* margin-bottom: 900; */
			width: 100%;
			height: auto;
			opacity: 1;
			/* border: #7610f2 solid 1px; */
			background: rgba(21, 14, 38, 1);
		}
	
		.swap_word1 {
			/*free face swap online*/
	
			/* left: 435px;
			top: 241px; */
			margin-top: -5vw;
			width: 100%;
			height: 15vw;
			text-align: center;
			/* opacity: 1; */
			/* display: flex; */
			/* position: absolute; */
			/** 文本1 */
			font-size: 2em;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: 40px;
			color: rgba(0, 0, 0, 1);
			vertical-align: top;
			/* border:#007bff solid 1px */
		}
	
		.swap_word2 {
			/*experience fun or real swaps: select sin*/
	
			/* left: 290px;
			top: 329px;
			width: 810px;
			height: 29px; */
			/* position: absolute; */
			display: flex;
			justify-content: center;
			opacity: 1;
			/** 文本1 */
			width: 100%;
			text-align: center;
			margin-top: 20px;
			display: flex;
			font-size: 1em;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: 1px;
			color: rgba(0, 0, 0, 1);
			vertical-align: top;
			padding: 1px;
			/* border: #ef24c0 solid 1px; */
		}
	
		.font_name3 {
			/*face swap*/
	
			/* left: 462px;
			top: 373px; */
			/* width: 118px;
			height: 35px; */
			opacity: 1;
			position: relative;
			/** 文本1 */
			margin-top: px;
			margin-left: 15px;
			margin-right: 15px;
			display: flex;
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0px;
			line-height: 10px;
			color: rgba(112, 112, 112, 1);
			text-align: right;
			vertical-align: top;
			/* border: #00ff40 solid 1px; */
			background-color: transparent;
			/* border: darkgoldenrod;
			border-radius: 8%; */
			cursor: pointer;
			/* border: #976af2 solid 1px */
		}
	
		.font_name3.isActive::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 3px;
			background: linear-gradient(to right, #1f1f1f, #007bff);
			border-radius: 5px;
			bottom: -5px
		}
	
		.font3_box {
			/* border: #00ff40 solid 1px; */
			margin-top: 8px;
			margin-left: 30px;
			margin-bottom: 8px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			/* margin-top: 20px;
		margin-left: 30px; */
			/* justify-content: space-between; */
			/* float:inline-start; */
			width: 90%;
			height: auto;
			/* border: #007bff solid 1px */
		}
	
		.template_img {
			position: relative;
			display: flex;
			gap: 5px;
			flex-wrap: wrap;
			/* border: #00ff40 solid 1px; */
			/* margin-bottom: 10px; */
		}
	
		.template_img img {
			position: relative;
			width: calc(50% - 20px);
			height: auto;
			justify-content: space-between;
			margin: 5px;
			/* margin-top: 50px; */
			/* margin-bottom: 10px; */
			/* width: 20%; */
			object-fit: cover;
			border-radius: 10px;
			/* 50%宽度减去间距 */
			box-sizing: border-box;
			align-items: center;
	
		}
	
		.selected-image {
			border-color: #b07ee2 solid 2px;
		}
	
		.template_type {
	
			display: flex;
			width: 100%;
			height: auto;
			position: relative;
			margin-top: 80px;
			display: flex;
			background: rgba(44, 39, 56, 1);
			/* align-items:center; */
			flex-direction: column;
			/* border: #ef24c0 solid 1px; */
		}
	
		.template_type_select {
	
			/*sporty*/
	
			/* left: 26px;
		top: 5.5px;
		width: 50px;
		height: 23px;
		opacity: 1; */
			/** 文本1 */
			display: flex;
			position: relative;
			margin: 5px;
			/* padding: auto; */
			text-align: center;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: 23px;
			color: rgba(255, 255, 255, 1);
			/* border: none; */
			/* background: seashell; */
			vertical-align: top;
			background-color: #01080f;
			cursor: pointer;
	
			/*路径*/
	
			/* left: 0px;
		top: 0px; */
			width: 102px;
			height: 35px;
			/* opacity: 1; */
			/* border: 1px solid rgba(72, 52, 212, 1); */
			background-color: transparent;
			border: none;
			cursor: pointer;
	
		}
	
		/* .template_type_select:hover { */
		/* background-color: rgb(246, 246, 248); */
		/* 悬停时背景颜色变为深蓝色 */
		/* } */
	
	
		.template_type_select.isActive {
			/*路径*/
	
			/* left: 0px;
			top: 0px; */
			/* content: ''; */
			/* position: absolute; */
			/* left: 0; */
			/* width: 102px;
			height: 35px; */
			/* opacity: 1; */
			background: rgba(104, 109, 224, 1);
			box-shadow: 0px 1px 2px rgba(20, 14, 62, 0.25);
			z-index: 2;
		}
	
		.upload_template {
			position: absolute;
			display: flex;
			margin-top: 20px;
			margin-bottom: 50px;
			margin-left: 20px;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 90%;
			height: 30px;
	
			/*路径*/
			/* width: 506px; */
			/* height: 40px; */
			/* opacity: 1; */
			background: rgba(204, 204, 204, 0.3);
	
			/** 文本1 */
			font-size: 20px;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: 38px;
			color: rgba(255, 255, 255, 1);
			vertical-align: top;
			/* border: #007bff solid 1px; */
			cursor: pointer;
			/* background-color: transparent; */
			border: none;
			/* cursor: pointer; */
		}
	
		.img_doc {
			width: auto;
			height: 100vw;
			display: flex;
			/* position: relative; */
			/* border: #00ff40 solid 1px; */
			/* align-items: center;
		justify-content: center; */
			flex-direction: column;
	
		}
	
		.img_doc1_box {
			/*face-swap-and-demonstrate-the-ability-to-generate-various-different-faces-of-a-man 1*/
			display: flex;
			margin-top: 5vw;
			overflow: hidden;
			width: 100%;
			height: 300px;
		}
	
	
		.img_doc1_box_box {
			/*create infinite faces with al face swap technology*/
	
			/* left: 915px; 
			top: 1168px; */
			/* margin-top: 50%;
		margin-left: 50%; */
			/* margin: 10px; */
			/* float: left; */

			display: flex;
			width: 100%;
			margin-top: 5vw;
			align-items: center;
			padding: auto;
			height: auto;
			opacity: 1;
			flex-direction: column;
			/* border: #007bff solid 1px; */
		}
	
		.img_doc1_box_doc1 {
			/*create infinite faces with al face swap technology*/
	
			/* left: 915px; 
			top: 1168px; */
			/* margin-top: 3vw;
			margin-left: 3vw; */
			display: flex;
			width: 90%;
			height: auto;
			opacity: 1;
			padding: auto;
			/** 文本1 */
			font-size: 10px;
			font-weight: 200;
			letter-spacing: 0px;
			color: rgba(255, 255, 255, 1);
			vertical-align: top;
			text-wrap: wrap;
			/* border: #007bff solid 1px; */
		}
	
		.img_doc1_box_doc2 {
	
			/*ai face swap has surged in popularity as a dynamic photo trend. its advanced algorithms accurately detect and track facial features, allowing users to effortlessly create realistic face swaps. Pook ai face swapper is one of the best free online tools for face swapping, you can use it to seamlessly swap face in any photo and enjoy endless fun while engaging with realistic face swap photos.*/
	
		
			width: 90%;
			height: auto;
			opacity: 1;
			display: flex;
			/** 文本1 */
			font-size: 13px;
			font-weight: 200;
			letter-spacing: 0px;
			/* line-height: 12px; */
			color: rgba(255, 255, 255, 1);
			vertical-align: top;
			text-wrap:wrap;
			/* border: #f462c8 solid 1px; */
		}
	
		.swap_boten {
			display: flex;
			margin-left: 10vw;
			width: 200px;
			height: 92px;
			opacity: 1;
			background: rgba(72, 52, 212, 1);
			/* border: #00ff40 solid 1px; */
			cursor: pointer;
			border: none;
		}
	
		.swap_boten.text {
	
			width: 178px;
			height: 38px;
			opacity: 1;
			/** 文本1 */
			font-size: 20px;
			font-weight: 300;
			align-items: center;
			justify-content: center;
			letter-spacing: 0px;
			line-height: 38px;
			color: rgba(255, 255, 255, 1);
			text-align: center;
			/* vertical-align: top; */
		}
	
		.doc2_box {
			/* left: 500px;
			top: 1886px; */
			/* margin-left: 5vw; */
	
			display: flex;
			align-items: center;
			justify-content: center;
			/* margin-top: 2vw; */
			margin: 2vw auto;
			width: 90%;
			height: 160px;
			opacity: 1;
			font-size: 1.5em;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: 30px;
			color: rgba(255, 255, 255, 1);
			vertical-align: top;
			text-align: center;
			/* 文字向左对齐 */
			word-wrap: break-word;
			/* 允许长单词或 URL 地址换行到下一行 */
			/* border: #7610f2 solid 1px;; */
		}
	
		.row_doc_box_base {
	
			display: flex;
			/* position: relative; */
			float:left;
			margin-top: 10px;
			margin: 0px auto;
			width: auto;
			height: auto;
			padding: 10px;
			/* opacity: 1; */
			flex-direction: column;
			/* overflow: hidden; */
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			/* 允许换行 */
			/* justify-content: space-between; */
			/* 项目之间的间距均匀分布 */
			/* border: #00ff40 solid 1px; */
			/* overflow: scroll; */
			/* border:#976af2 solid 1px; */
		}
	
		.col_doc_box_base {
	
			display: flex;
			position: relative;
			float: left;
			margin-top: 10px;
			width: auto;
			height: 40vw;
			opacity: 1;
			flex-direction: column;
			/* overflow: hidden; */
			/* flex-wrap: wrap; */
			/* 允许换行 */
			/* justify-content: space-between; */
			/* 项目之间的间距均匀分布 */
			/* border: #00ff40 solid 1px; */
			overflow: hidden;
		}
	
		/* 第一块图文 */
		.doc3_box_1 {
	
			/*rectangle 13*/
	
			/* display: flex;
			position: relative; */
			/* margin-top: 10px; */
			/* margin-left: 5vw; */
			margin: 10px auto;
			/* left: 0px;
		top: 100px; */
			width: 100%;
			height: 40%;
			/* opacity: 1; */
			/* background: rgba(40, 25, 65, 1); */
		}
	
		.doc3_box_1_image {
			max-width: 80%;
			/* 图片最大宽度为容器的100% */
			max-height: auto;
			/* 图片最大高度为容器的100% */
			display: block;
			/* 将图片转换为块级元素 */
			margin: 0 auto;
			/* 水平居中 */
			object-fit: cover;
			overflow: hidden;
		}
	
		.doc3_box_1_text_box {
	
			opacity: 1;
			border-radius: 10px;
			background: rgba(255, 255, 255, 1);
			height: 38%;
		}
	
	
		.doc3_box_1_text {
	
	
			/*ai face swap has surged in popularity*/
	
			/* left: 83px;
		top: 2399px;
		width: 474px;
		height: 40px;
		opacity: 1; */
			/** 文本1 */
	
			font-size: 1.4em;
			font-weight: 350;
			letter-spacing: 0px;
			line-height: auto;
			color: rgba(0, 0, 0, 1);
			vertical-align: top;
			/* width: 90%; */
			/* 文本最大宽度为容器的100% */
			/* display: block; */
			/* 将文本转换为块级元素 */
			/* margin: 0 auto; */
			/* 水平居中 */
			/* text-align: left; */
			/* 文字向左对齐 */
			word-wrap: break-word;
			/* 允许长单词或 URL 地址换行到下一行 */
			/* font-size: 24px; */
			/* color: #f5f2f4; */
			/* border: #ff0055 solid 1px;; */
		}
	
	
		.doc3_box_1_text2 {
	
			/** 文本1 */
			font-size: 1em;
			font-weight: 00;
			letter-spacing: 0px;
			line-height: auto;
			color: rgba(0, 0, 0, 1);
			vertical-align: top;
			word-wrap: break-word;
	
		}
	
		.swap_button_style {
			width: 8em;
			height: 2em;
			opacity: 1;
			font-size: 1.3em;
			font-weight: 100;
			color: #f5f2f4;
			background: rgba(72, 52, 212, 1);
			/* border: #00ff40 solid 1px; */
			cursor: pointer;
			border-radius: 15px;
			border: none;
		}
	
		.box_center {
			display: flex;
			margin: 0 auto;
			justify-content: center;
			align-items: center;
		}
	
		/* 第3块图文 */
		.box_text_base {
	
			margin-left: 2vw;
			font-size: 1.4em;
			font-weight: 350;
			letter-spacing: 0px;
			line-height: auto;
			color: rgb(250, 247, 247);
			vertical-align: top;
			width: 90%;
			align-content: center;
			/* 文本最大宽度为容器的100% */
			/* display: block; */
			/* 将文本转换为块级元素 */
			/* margin: 0 auto; */
			/* 水平居中 */
			text-align: left;
			/* 文字向左对齐 */
			word-wrap: break-word;
	
		}
	
	
		.box_text_base2 {
	
			/** 文本1 */
			width: 100%;
			height: auto;
			margin-top: 2vw;
			margin-left: 5vw;
			font-size: 1em;
			font-weight: 200;
			letter-spacing: 0px;
			line-height: auto;
			color: rgb(248, 245, 245);
			vertical-align: top;
			/* word-wrap:break-word; */
			text-wrap: wrap;
			overflow-wrap: break-word; /* 允许在单词内换行 */
			word-wrap: break-word; /* 兼容旧版浏览器 */
			overflow: auto; /* 如果内容超出容器大小，显示滚动条 */
			padding: 10px;
	
		}
	
		.box_img_base {
	
			/* max-width: 50%; */
			/* 图片最大宽度为容器的100% */
			float: left;
			max-width: 90%;
			max-height: auto;
			/* 图片最大高度为容器的100% */
			display: block;
			/* 将图片转换为块级元素 */
			margin: 0 auto;
			/* 水平居中 */
			object-fit: cover;
		}
	
		.fdvfd {
			/*rectangle 14*/
	
			/* left: 1104px;
			top: 3571px; */
			/* margin-top: 7vw; */
			margin: 10px auto;
			width: 90%;
			height: auto;
			opacity: 1;
			background: rgba(217, 217, 217, 0);
			/* overflow: hidden; */
			/* object-fit:auto; */
		}
	
	
		.buy_back_box {
			/*rectangle 23*/
	
			/* left: 109px;
			top: 8298px; */
			/* display: flex;
			position: absolute; */
			width: 90%;
			height: auto;
			opacity: 1;
			border-radius: 8px;
			/* background: rgba(255, 255, 255, 1); */
			/* border: #007bff solid 1px; */
		}
	
		.buy_back_box_text {
			/*free and quaility face swaps*/
	
			/* left: 119px;
			top: 8322px; */
			/* width: 470px;
			height: 46px; */
			display: flex;
			justify-items: center;
			align-items: center;
			margin: 2px auto;
			/* margin-left: 10px; */
			width: 100%;
			height: 60px;
			text-align: center;
			/** 文本1 */
			font-size: 1.5rem;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: auto;
			color: rgb(11, 11, 11);
			vertical-align: top;
			background-color: #f5f2f4;
			border-radius: 12px;
			/* border: #ef24c0 solid 1px; */
	
		}
	
		.buy_back_box_text2 {
			/*free and quaility face swaps*/
			margin: 2px auto;
			padding: 10px;
			/** 文本1 */
			width: 100%;
			height: auto;
			font-size: 1rem;
			font-weight: 300;
			text-wrap: wrap;
			letter-spacing: 0px;
			line-height: auto;
			color: rgb(252, 250, 250);
			vertical-align: top;
			border-radius: 12px;
			/* border: #ef24c0 solid 1px; */
			overflow: scroll;
	
		}
	
		.stars {
			/*star 1*/
			/* position: absolute; */
	
			margin: 1vw auto;
			/* border: #00ff40 solid 1px; */
			/* width: auto; */
			/* height: 30px; */
			/* display: flex; */
			text-align: center;
			align-items: center;
			justify-content: space-between;
			flex-direction: row;
			/* object-fit: cover; */
		}
	
		.faq {
	
			/*1.can i face swap pnline for free*/
			display: flex;
			align-items: center;
			justify-content: left;
			margin: 5vw auto;
			width: 80%;
			height: 20px;
			opacity: 1;
			/** 文本1 */
			font-size: 20px;
			font-weight: 200;
			letter-spacing: 0px;
			line-height: 20px;
			color: rgba(255, 255, 255, 1);
			vertical-align: top;
			/* border: #a694a0 solid 1px; */
		}
	
		.conect_us {
	
			display: flex;
			align-items: center;
			justify-content: left;
			/* margin: 30px auto; */
			flex-direction: row;
			color: #f5f2f4;
			letter-spacing: 2px;
			word-spacing: 2px;
			border-top: #3e3f3e solid 2px;
	
		}
	
		.connect_us_box {
			
			/* height: 500px; */
			
			display: flex;
			margin-top: 10px;
			/* margin: 10px; */
			font-size: 10px;
			font-weight: 200;
			letter-spacing: 0px;
			line-height: 40px;
			align-items: center;
			justify-content: left;
			/* margin: 30px auto; */
			flex-direction: row;
			color: #f5f2f4;
			letter-spacing: 2px;
			word-spacing: 2px;
			/* border-top: #3e3f3e solid 2px; */
		}
}


</style>