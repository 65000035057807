export async function fetchImage(img_url) {
  console.log("img_url", img_url)
  try { 
    if (!img_url.includes('https')) {
    const response = await fetch(img_url);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } else {
    return img_url;
  }
  } catch (error) {
    alert('image upload faild,please check your picture',error)
  }
  
}