<template>
  <div class="container">
    <div class="not-found-container">
      <div class="not-found-content">
        <h1 class="not-found-title">404</h1>
        <p class="not-found-message">Oops! The page you are looking for does not exist.</p>
        <router-link to="/" class="not-found-link">Go back to Home</router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

onMounted(() => {
  setTimeout(() => {
    router.push('/');
  }, 2000); // 2 秒后自动跳转到首页
});
</script>

<style scoped>
.container {
  /*路径*/
  left: 0px;
  top: 0px;
  width: auto;
  height: auto;
  background: url("../assets/background.png");
  overflow: hidden;
}

.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.not-found-content {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
}

.not-found-title {
  font-size: 100px;
  color: #ff6347;
  margin: 0;
}

.not-found-message {
  font-size: 24px;
  color: #333;
  margin: 20px 0;
}

.not-found-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff6347;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.not-found-link:hover {
  background-color: #e55b3f;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>