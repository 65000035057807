import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithEmailAndPassword } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBQpoboennxLpw3K7y1MUGW0DkBT0p7yrc",
  authDomain: "new-first-a0c9f.firebaseapp.com",
  projectId: "new-first-a0c9f",
  storageBucket: "new-first-a0c9f.appspot.com",
  messagingSenderId: "997828231224",
  appId: "1:997828231224:web:c3dd855bf6e786ddbb66cd",
  measurementId: "G-4HD3PK5Z56"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export {auth, googleProvider, facebookProvider, signInWithEmailAndPassword };