// src/i18n/index.js

import { createI18n } from 'vue-i18n';

// 导入语言文件
import ar from '../locales/ar.json'
import de from '../locales/de.json'
import en from '../locales/en.json'
import es from '../locales/es.json'
import fr from '../locales/fr.json'
import hi from '../locales/hi.json'
import it from '../locales/it.json'
import ja from '../locales/ja.json'
import ko from '../locales/ko.json'
import nl from '../locales/nl.json'
import pt from '../locales/pt.json'
import ru from '../locales/ru.json'
import tr from '../locales/tr.json'
import vi from '../locales/vi.json'
import zhCN from '../locales/zh-CN.json'
import zhTW from '../locales/zh-TW.json'


const messages = { en, ar,de,es,fr,hi,it,ja,ko,pt,ru,nl,tr,vi,zhCN,zhTW };

// 创建 i18n 实例
const i18n = createI18n({
locale: 'en', // 默认语言
fallbackLocale: 'en', // 回退语言
messages,
});

export default i18n;